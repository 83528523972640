export function isExternalLink(link: string): boolean {
    return matchesRegex(/https?:\/\//gi, link);
}

export function isInternalAnchorLink(link: string): boolean {
    return matchesRegex(/#.+$/, link);
}

export function isMailtoOrTelLink(link: string): boolean {
    return matchesRegex(/(tel:|mailto:)/gi, link);
}

export function isAbsoluteLink(link: string): boolean {
    return isExternalLink(link) || isMailtoOrTelLink(link);
}

function matchesRegex(regex: RegExp, str: string): boolean {
    return regex.test(str);
}

export function getFragmentFromLink(link: string): string {
    const indexOfHash = link.indexOf('#');
    return link.substr(indexOfHash + 1);
}
