import * as React from 'react';

const SERVICES = [
  {
    icon: 'home_work',
    title: 'Architektur',
    description: 'Wir erstellen gemeinsam mit Ihnen hochwertige und einzigartige Räume zum Leben. Sie bekommen alles aus einer Hand und müssen keinen zusätzlichen Architekten hinzuziehen.'
  },
  {
    icon: 'border_bottom',
    title: 'Statik',
    description: 'Standsicherheit und Energieeffizienz sind bei uns von Beginn an Teil des Konzeptes. Das ermöglicht detailgenaue Planung mit mehr Kosteneffizienz.'
  },
  {
    icon: 'emoji_emotions',
    title: 'Begleitung',
    description: 'Wir sind Ihr Partner für den gesamten Bauprozess und helfen bei Bedarf auch im Kontakt mit anderen Betrieben mit unserem Fachwissen und unserer Erfahrung.'
  }
];

export default props => (
  <div className="container-fluid bg-gradient-orange" id="leistungen">
    <div className="container py-5">

      <div className="row my-4 my-sm-5 d-flex justify-content-center">
        <div className="col-12 col-md-10">
          <h1 className="text-center">
            Alle Leistungen aus einer Hand.<br/>Vom Erstgespräch bis zum fertigen Gebäude.
          </h1>
        </div>
      </div>

      <div className="row py-5 d-flex justify-content-around">
        {SERVICES.map((service, index) => (
          <div key={index} className="col-12 col-sm-6 col-lg-4 text-center mb-4 mb-lg-0">
            <i className="material-icons icon-big color-black">{service.icon}</i>
            <h2 className="pt-2 pb-3">
              {service.title}
            </h2>
            <p>
              {service.description}
            </p>
          </div>
        ))}
      </div>

    </div>
  </div>
);
