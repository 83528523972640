import * as React from 'react';
import Styles from './contact.module.scss';

const CONTACT_OPTIONS = [
  {
    icon: 'phone',
    description: 'Jetzt anrufen',
    href: 'tel:+4956199792040'
  },
  {
    icon: 'mail_outline',
    description: 'Anfrage per Mail stellen',
    href: 'mailto:info@volkmann-planung.de'
  },
  {
    icon: 'place',
    description: 'Besuchen Sie uns in unserem Büro',
    href: 'https://maps.google.com/maps?cid=10461366801622584669',
    targetBlank: true
  }
];

export default props => (
  <div className="container-fluid bg-gradient-orange" id="kontakt">
    <div className="container py-5">
      <div className="row my-5 d-flex justify-content-center">
        <div className="col-12 col-md-10 text-center">
          <h1 className="mb-3">
            Kontaktieren Sie uns unverbindlich.<br></br>Wir beraten Sie gerne.
          </h1>
          <p>
            Wir begleiten unsere Bauherren professionell und auf Augenhöhe.
            Zahlreiche{' '}
            <a href="https://www.volkmann-planung.de/projekte" className="unstyled font-weight-bold" target="_blank" rel="noopener nofollow">
              erfolgreiche Projekte
            </a>{' '}
            und glückliche Kunden in und um Kassel sprechen für sich.
          </p>
        </div>
      </div>

      <div className="row py-5 d-flex justify-content-between">
        {
          CONTACT_OPTIONS.map((contactOption, index) => (
            <div key={index} className="col-12 col-md-6 col-lg-3 mb-4 mb-lg-0 d-flex flex-column text-center">
              <a href={contactOption.href} className="unstyled" target={contactOption.targetBlank ? '_blank' : undefined} rel={contactOption.targetBlank ? 'noopener nofollow' : undefined}>
                <i className="material-icons color-black icon-big">{contactOption.icon}</i>
                <p className={`pt-4 ${Styles.contactDescription}`}>{contactOption.description}</p>
              </a>
            </div>
          ))
        }
      </div>
    </div>
  </div>
)
