import React, { useEffect, useState } from 'react';
import { Breakpoint } from '../../../utils/breakpoints';
import Link from '../link/link';
import Styles from './navbar.module.scss';

const LINKS = [
    {
        link: '/',
        text: 'Leistungen',
        fragment: 'leistungen'
    },
    {
        link: '/',
        text: 'Prozess',
        fragment: 'prozess'
    },
    {
        link: '/',
        text: 'Vorteile',
        fragment: 'vorteile'
    },
    {
        link: '/',
        text: 'Kontakt',
        fragment: 'kontakt'
    }
];

export default props => {
    const [isNavbarOpen, setNavbarOpen] = useState(false);
    const closeNavbar = () => {
        document.body.classList.remove('no-scroll');
        setNavbarOpen(false);
    }
    const openNavbar = () => {
        document.body.classList.add('no-scroll');
        setNavbarOpen(true);
    }
    const toggleNavbar = () => {
        if (isNavbarOpen) {
            closeNavbar();
        } else {
            openNavbar();
        }
    };
    const closeNavbarOnResize = () => {
        if (isNavbarOpen && window.innerWidth >= Breakpoint.Medium) {
            closeNavbar();
        }
    }

    useEffect(() => {
        window.addEventListener('resize', closeNavbarOnResize);
    }, []);

    return (
        <nav className={`navbar navbar-expand-md navbar-light bg-light d-flex flex-column ${Styles.nav} ${isNavbarOpen ? Styles.fullscreen : ''}`}>
            <div className="container">
                <Link className="navbar-brand pl-3 d-flex align-items-center" to="">
                    <img src="/images/volkmann-planung.svg" className="brand-image" alt="Volkmann Planung"></img>
                </Link>
                <button className="navbar-toggler border-0 outline-none" onClick={() => toggleNavbar()}>
                    <i className="material-icons fade-in">{isNavbarOpen ? 'close' : 'menu'}</i>
                </button>
                <div className={`collapse navbar-collapse text-center text-md-left ${isNavbarOpen ? 'show' : ''}`}>
                    <ul className="navbar-nav ml-md-auto">
                        {
                            LINKS.map((link, index) => (
                                <li key={index} className="nav-item">
                                    <Link className="unstyled p-0 color-black"
                                        to={link.link + (link.fragment ? '#' + link.fragment : '')}
                                        callback={() => closeNavbar()}>
                                        {link.text}
                                    </Link>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </nav>
    );
};
