import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '../components/global/layout/layout';
import Hero from '../components/index/hero/hero';
import Services from '../components/index/services/services';
import Process from '../components/index/process/process';
import Advantages from '../components/index/advantages/advantages';
import Faq from '../components/index/faq/faq';
import Contact from '../components/index/contact/contact';

export const pageQuery = graphql`
  query IndexQuery {
    allPrismicFaqEntry {
      nodes {
        data {
          question {
            text
          }
          answer {
            html
          }
        }
      }
    }
  }
`

export default (props) => (
  <Layout
      title="Ihr Partner für den Wohnhausbau"
      description="Wir planen und organisieren den Hausbau vom Erstgespräch bis zur Übergabe. Als erfahrenes Ingenieurbüro mit Ursprung im Handwerk planen und begleiten wir Ihr Gebäude von der Skizze bis zur Fertigstellung."
      image="/images/jens-volkmann.jpg">
    <Hero/>
    <Services/>
    <Process/>
    <Advantages/>
    <Faq entries={props.data.allPrismicFaqEntry.nodes}/>
    <Contact/>
  </Layout>
);
