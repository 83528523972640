import React, { useState } from 'react';
import Styles from './faq.module.scss';

export default props => {
  const [selectedEntry, setSelectedEntry] = useState(undefined);
  return (
    <div className="container py-5">
      <div className="row my-5 d-flex justify-content-center">
        <div className="col-12 col-md-10">
          <h1 className="text-center">
            Sie haben offene Fragen? Lesen Sie in unserem FAQ oder kontaktieren Sie uns persönlich. Wir helfen Ihnen
            gerne weiter.
          </h1>
        </div>
      </div>

      <div className="row pt-5">
        <div className="col-12">
          <ul className="list-unstyled">
            {
              props.entries.map((entry, index) => (
                <li className="py-4" key={index}>
                  <div className="pb-5">
                    <div className="p-0 d-flex justify-content-between cursor-pointer"
                         onClick={() => setSelectedEntry(selectedEntry === entry ? null : entry)}>
                      <h2 className={`${Styles.faqEntryTitle} no-select cursor-pointer`}>{entry.data.question.text}</h2>
                      <i className={`cursor-pointer material-icons color-orange no-select ${Styles.faqEntryIcon}`} aria-label="Mehr erfahren">
                        {selectedEntry === entry ? 'remove' : 'add'}
                      </i>
                    </div>
                    <div
                      className={`col-12 col-md-11 p-0 pt-5 ${selectedEntry === entry ? 'd-block' : 'd-none'}`} dangerouslySetInnerHTML={{ __html: entry.data.answer.html }}></div>
                  </div>
                  {(props.entries.length - 1) !== index ? <hr/> : null}
                </li>
              ))
            }
          </ul>
        </div>
      </div>

      <div className="row my-5 d-flex justify-content-center">
        <div className="col-12 col-md-10">
          <p className="text-center">
            Weitere häufige Fragen unserer Bauherren beantworten wir{' '}
            <a href="https://www.volkmann-planung.de/faq" className="unstyled font-weight-bold" target="_blank" rel="noopener nofollow">
              auf unserer Firmen-Webseite
            </a>.
            Dort finden Sie auch ausführliche Informationen zu unseren Leistungen in den Bereichen{' '}
            <a href="https://www.volkmann-planung.de/planung" className="unstyled font-weight-bold" target="_blank" rel="noopener nofollow">
              Planung
            </a>,{' '}
            <a href="https://www.volkmann-planung.de/statik" className="unstyled font-weight-bold" target="_blank" rel="noopener nofollow">
              Statik
            </a> und{' '}
            <a href="https://www.volkmann-planung.de/gutachten" className="unstyled font-weight-bold" target="_blank" rel="noopener nofollow">
              Gutachten
            </a>.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-12 d-flex justify-content-center pb-5">
          <a className="button color-white unstyled my-4" href="mailto:info@volkmann-planung.de">
            Unverbindliche Anfrage stellen
          </a>
        </div>
      </div>
    </div>
  )
}
