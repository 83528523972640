import React from 'react';
import Styles from './footer.module.scss';

export default props => {
    return (
        <footer className={`container py-5 ${Styles.footer}`}>
            <div className="row">
                <div className="col-12 col-md-6 col-lg-3 mb-3 mb-md-4 mb-lg-0">
                    <h2 className="font-weight-bold color-orange">Unternehmen</h2>
                    <address className="mb-1">
                        Volkmann Planung<br></br>
                        Korbacher Straße 244<br></br>
                        34132 Kassel
                    </address>
                    <a className="unstyled" href="https://maps.google.com/maps?cid=10461366801622584669" target="_blank" rel="noopener nofollow">
                        Auf Google Maps ansehen
                    </a>
                </div>
                <div className="col-12 col-md-6 col-lg-3 mb-3 mb-md-4 mb-lg-0">
                    <h2 className="font-weight-bold color-orange">Kontakt</h2>
                    <ul className="list-unstyled m-0">
                        <li>
                            Tel.: <a className="unstyled" href="tel:+4956199792040">0561 9979 2040</a>
                        </li>
                        <li>
                            Fax: <a className="unstyled" href="fax:+4956244779832">05624 4779 832</a>
                        </li>
                        <li>
                            <a className="unstyled" href="mailto:info@volkmann-planung.de">info@volkmann-planung.de</a>
                        </li>
                    </ul>
                </div>
                <div className="col-12 col-md-6 col-lg-3 mb-3 mb-md-0">
                    <h2 className="font-weight-bold color-orange">Leistungen</h2>
                    <ul className="list-unstyled m-0">
                        <li>
                            <a href="https://www.volkmann-planung.de/planung" className="unstyled" target="_blank" rel="noopener nofollow">Bauplanung</a>
                        </li>
                        <li>
                            <a href="https://www.volkmann-planung.de/statik" className="unstyled" target="_blank" rel="noopener nofollow">Statik &amp; Wärmeschutz</a>
                        </li>
                        <li>
                            <a href="https://www.volkmann-planung.de/gutachten" className="unstyled" target="_blank" rel="noopener nofollow">Gutachten</a>
                        </li>
                        <li>
                            <a href="https://www.volkmann-planung.de/projekte" className="unstyled" target="_blank" rel="noopener nofollow">Projekte ansehen</a>
                        </li>
                    </ul>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                    <h2 className="font-weight-bold color-orange">Rechtliches</h2>
                    <ul className="list-unstyled m-0">
                        <li>
                            <a href="https://www.volkmann-planung.de/impressum" className="unstyled" target="_blank" rel="noopener nofollow">Impressum</a>
                        </li>
                        <li>
                            <a href="https://www.volkmann-planung.de/datenschutz" className="unstyled" target="_blank" rel="noopener nofollow">Datenschutz</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <hr className="my-md-4"></hr>
                    <a className={`unstyled ${Styles.expoLink}`} href="https://www.exportarts.io" rel="noopener nofollow">
                        Made with
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                            <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/>
                        </svg>
                        by exportarts.io
                    </a>
                </div>
            </div>
        </footer>
    );
};
