import React, { useEffect }  from 'react';
import Styles from './process.module.scss'
import ProcessStep from './process-step/process-step'
import { getScrollPercentage } from '../../../utils/window';
import { Breakpoint } from '../../../utils/breakpoints';

const PROCESS_STEPS = [
  {
    title: 'Erstgespräch',
    description: 'Gemeinsam klären wir die Anforderungen an Ihr neues Wohnhaus, An- oder Umbauprojekt. Die Beratung ist für Sie unverbindlich und kostenlos.',
    content: 'Wenn Sie schon erste Vorstellungen, Skizzen oder sonstige Angaben haben, bringen Sie diese gerne zum Erstgespräch mit.'
  },
  {
    title: 'Entwurf erstellen',
    description: 'Auf Basis Ihrer Wünsche und der lokalen Gegebenheiten legen wir fest, welche Raumaufteilung und Größen sinnvoll sind. Der Entwurf wird anschließend verfeinert, bis Sie zufrieden sind.',
    content: 'Wir gehen ausführlich auf Ihre Vorstellungen ein und bringen unsere mehr als 20-jährige Erfahrung im Bau von Wohnhäusern mit ein. So kommen wir gemeinsam zu einem optimalen Ergebnis.'
  },
  {
    title: 'Bauantrag',
    description: 'Der Bauantrag stellt die formale Anfrage beim zuständigen Bauamt dar. Wir erstellen alle nötigen Unterlagen. Nur die Unterschrift müssen Sie leisten.',
    content: 'Nach der Genehmigung des Bauantrages kann die Baumaßnahme beginnen. Sollten sich Rückfragen oder Änderungswünsche seitens der Behörde ergeben, besprechen wir diese und setzen die notwendigen Anpassungen in Absprache mit Ihnen um.'
  },
  {
    title: 'Statik & Wärmeschutz',
    description: 'Während der Bearbeitung des Bauantrags durch die Behörde erstellen wir die nötigen Statischen Berechnungen und Wärmeschutznachweise nach den gültigen Vorschriften.',
    content: 'Wir kennen die Anforderungen der ausführenden Betriebe an statische Berechnungen und erstellen alle Dokumente mit optimaler Vorbereitung für den Baubetrieb.'
  },
  {
    title: 'Detailplanung',
    description: 'Bei Bedarf erstellen wir Detail- und Ausführungspläne für die ausführenden Betriebe wie etwa Zimmereien oder Maurerbetriebe.',
    content: 'Dies ist nicht immer notwendig und nur bei komplexeren Gebäudeteilen oder Gegebenheiten des Grundstücks von Bedarf. In der Regel arbeiten die ausführenden Betriebe selbstständig anhand unserer Planungs- und Statik-Unterlagen.'
  },
  {
    title: 'Baubegleitung',
    description: 'Wenn gewünscht begleiten wir den gesamten Verlauf des Bauprojektes und überwachen die beteiligten Unternehmen.',
    content: 'In jedem Falle beraten wir Sie als Bauherren ausführlich und bereiten Sie auf das Projekt vor. In der Regel sind unsere Bauherren immer in der Lage, Ihr Wohnhausprojekt selbstständig zu steuern.'
  },
  {
    title: 'Abnahme',
    description: 'Bei Abschluss der Bauphase überprüfen wir vor Ort die fachgerechte Ausführung und geben das Gebäude für die Nutzung frei.',
    content: 'Hierbei achten wir besonders auf die Einhaltung der Statischen Berechnung, damit eine sichere und lange Lebensdauer Ihres Gebäudes gewährleistet ist.'
  }
];

function animateImageOnScroll() {
  requestAnimationFrame(() => {
    const scroll = getScrollPercentage();
    const elements = document.getElementsByClassName('translate-on-scroll');
    for (let index = 0; index < elements.length; index++) {
      elements.item(index).setAttribute('style', `transform: translateY(${scroll * 2}px)`);
    }
  })
}

export default props => {
  useEffect(() => {
    if (window.innerWidth >= Breakpoint.Large) {
      window.addEventListener('scroll', animateImageOnScroll);
    }
  }, []);

  return (
    <div className="container py-5" id="prozess">

      <div className="row my-5 d-flex justify-content-center">
        <div className="col-12 col-md-9">
          <h1 className="text-center">
            Verschwenden Sie kein Geld und keine Zeit.<br></br>So läuft der Bauprozess mit uns.
          </h1>
        </div>
      </div>

      <div className="position-relative">

        <div className={`h-100 w-100 position-absolute d-flex flex-column justify-content-around`}>
          <div className="row py-5">
            <div className="col-6">
              <img className="w-100 translate-on-scroll" src="/images/process/apartment-1.jpg" alt="Haus von innen"/>
            </div>
          </div>
          <div className="row py-5">
            <div className="offset-6 col-6">
              <img className="w-100 translate-on-scroll" src="/images/process/apartment-2.jpg" alt="Haus von innen"/>
            </div>
          </div>
          <div className="row py-5">
            <div className="col-6">
              <img className="w-100 translate-on-scroll" src="/images/process/apartment-1.jpg" alt="Haus von innen"/>
            </div>
          </div>
        </div>

        <ol className="list-unstyled py-5">
          {PROCESS_STEPS.map((step, index) => (
            <ProcessStep key={index} step={step} index={index}/>
          ))}
        </ol>
      </div>

    </div>
  )
}
