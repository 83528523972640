import * as React from 'react';
import Styles from './advantages.module.scss';

const ADVANTAGES = [
  'Kostenfreie und unverbindliche Erstberatung',
  'Fundierte Erfahrung im Zimmerer- und Dachdeckerhandwerk',
  'Entwurf, Planung und Statik aus einer Hand',
  'Effiziente und kostenoptimierte Bauausführung',
  'Persönlicher Kontakt und Einsatz für Ihr Projekt'
];

export default props => (
  <div className="container-fluid bg-gradient-orange py-5" id="vorteile">
    <div className="container my-5">
      <div className="row">
        <div className="col-12 bg-white">
          <div className="row">
            <div className="col-12 col-lg-6 p-5">
              <div className="row d-flex justify-content-center">
                <h2 className="col-12 col-lg-10 text-center pb-3">
                  Zusammen planen wir Ihr Traumhaus.
                </h2>
                <ul className="col-12 pt-3 pb-5 p-0 p-lg-4 list-unstyled">
                  {
                    ADVANTAGES.map((advantage, index) => (
                      <li key={index} className="d-flex align-items-center mb-3">
                        <i className={`material-icons color-blue ${Styles.advantageIcon}`}>done</i>
                        <p className="m-0 p-0 pl-4">{advantage}</p>
                      </li>
                    ))
                  }
                </ul>
                <a className="button color-white unstyled my-4" href="mailto:info@volkmann-planung.de">
                  Unverbindliche Anfrage stellen
                </a>
              </div>
            </div>
            <div className={`col-12 col-lg-6 p-0 ${Styles.personImage}`}>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)
