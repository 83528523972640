import React, { useState } from 'react';
import Styles from './process-step.module.scss';

export default props => {
  const [open, setSelectedStep] = useState(undefined);

  return (
    <li className="row py-4 d-flex justify-content-center">
      <div className={`col-10 col-md-8 col-lg-7 col-xl-6 p-5 bg-gradient-orange position-relative ${Styles.processCard}`}>
        <span className={``}>
          {props.index + 1}
        </span>
        <div className="row d-flex flex-column">
          <h2>
            {props.step.title}
          </h2>
          <p className="col-10 p-0 pt-4">
            {props.step.description}
          </p>
          <div className={`position-relative ${open ? Styles.show : Styles.hide}`}>
            <p className={`position-absolute col-10 pt-4 p-0`}>
              {props.step.content}
            </p>
          </div>
          <a onClick={() => setSelectedStep(!open)} className={`color-orange pt-3 cursor-pointer ${Styles.processDiscover}`}>
            {open ? 'Weniger anzeigen' : 'Mehr anzeigen'}
          </a>
        </div>
      </div>
    </li>
  )
}
