import React from 'react';
import Helmet from 'react-helmet';
import Footer from '../footer/footer';
import Navbar from '../navbar/navbar';

// Helmet does not support React.Fragment, therefore the below syntax
// see: https://github.com/nfl/react-helmet/issues/342
const Meta = ({ title, description, image }) => (
    <Helmet>
        {title && <title>{title}</title>}
        {title && <meta name="og:title" content={title}></meta>}
        {description && <meta name="description" content={description}></meta>}
        {description && <meta name="og:description" content={description}></meta>}
        {image && <meta name="image" content={image}></meta>}
        {image && <meta name="og:image" content={image}></meta>}
    </Helmet>
);

export default props => (
    <React.Fragment>
        <Navbar/>
        <Meta
            title={`${props.title && props.title.length > 0 ? props.title + ' - ' : ''}Volkmann Planung`}
            description={props.description}
            image={props.image}>
        </Meta>
        {props.children}
        <Footer/>
    </React.Fragment>
)
