import * as React from 'react';
import Styles from './hero.module.scss';
import Link from '../../global/link/link';

export default props => (
  <div className={`container-fluid d-flex align-items-center ${Styles.heroContainer}`}>
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6">
          <h1 className={Styles.heroHeadline}>
            Wir planen und organisieren den Hausbau vom Erstgespräch bis zur Übergabe.
          </h1>
          <p className={`my-4 ${Styles.heroSubHeadline}`}>
            Als erfahrenes Ingenieurbüro mit Ursprung im Handwerk planen und
            begleiten wir Ihr Gebäude von der Skizze bis zur Fertigstellung.
            Erfahren Sie hier, wie Ihr Bauprojekt mit uns abläuft.
          </p>
          <Link className="d-block mb-4 mb-sm-5 unstyled font-weight-bold" to="/#leistungen">Mehr erfahren</Link>
          <a className="button color-white unstyled" href="mailto:info@volkmann-planung.de">
            Unverbindliche Anfrage stellen
          </a>
        </div>
      </div>
    </div>
    <div className={`${Styles.heroImageWrapper}`}>
      <div className={`offset-7 col-5 ${Styles.heroImageContainer}`}>
        <img className={Styles.heroImage} src="/images/construction.svg" alt="Personen bauen ein Haus"/>
      </div>
    </div>
  </div>
);
